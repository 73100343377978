<template>
  <div class="recipes">
    <div v-if="this.$root.$data.currentRecipe == ''">
      <h1>Recipes</h1>

      <div class="wrapper">
        <span class="fieldText">Search by name:</span>
        <div class="search">
          <form class="pure-form">
            <input v-model="searchText" />
          </form>
        </div>
      </div>

      <div class="recipe-list">
        <div class="recipe-link recipe" @click="select(recipe.id)" v-for="recipe in searchedRecipes" :key="recipe.id">
          <img class="recipe-img" :src="'images/'+recipe.image">
          <span class="recipe-name">{{recipe.name}}</span>
        </div>
      </div>

    </div>
    <div v-else>
      <h1>{{currentRecipe.name}}</h1>
      <img class="recipe-page-img" :src="'/images/'+currentRecipe.image">
      <div class="content-right">
        <div class="ingredient-section">
          <h2>Ingredients</h2>
          <div class="ingrediant-subsections">
            <div class="ingrediant-subsection" v-for="ingrediantSubsection in currentRecipe.ingrediants" :key="ingrediantSubsection[0]">
              <h3>{{ingrediantSubsection[0]}}</h3>
              <ul class="ingredients"> <!-- ingredient-item -->
                <li class="ingredient-item" v-for="ingrediant in ingrediantSubsection[1]" :key="ingrediant">{{ingrediant}}</li>
              </ul>
            </div>
          </div>
        </div>

        <hr>

        <div class="instruction-section">
          <h2>Instructions</h2>
          <ol class="instructions"> <!-- instruction-point -->
            <li class="ingredient-item" v-for="instruction in currentRecipe.instructions" :key="instruction">{{instruction}}</li>
          </ol>
        </div>

        <div class="citation"> <!-- Put Original Recipe URL Here -->
          <p>From <a target="_blank" :href="''+currentRecipe.citationURL+''">
          {{currentRecipe.citationName}}</a> </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Recipes",
    props: {
      recipe: Array
    },
    data() {
      return {
        searchText: '',
      }
    },
    computed: {
      searchedRecipes () {return this.$root.$data.recipes.filter(rec => rec.name.toLowerCase().trim().search(this.searchText.toLowerCase().trim()) >= 0)},
      currentRecipe () {return this != null ? this.$root.$data.recipes[this.$root.$data.currentRecipe - 1] : ''}
    },
    methods: {
      select(recipeID) {
        this.$root.$data.currentRecipe = recipeID;
      }
    }
  }
</script>

<style>
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .search {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 50%;
  }

  form {
    display: table;
    width: 100%;
  }

  .fieldText {
    margin-right: 10px;
    font-size: 25px;
  }

  input {
    display: table-cell;
    font-size: 20px;
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    height: 40px;
  }


  .recipes {
    margin-left: 45px;
    margin-right: 45px;
  }

  .content-right {
    text-align: left;
  }

  .recipe-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .recipe-link {
    display: block;
    background-color: #E5E5E5;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    font-size: 40px;
    color: #000000;
  }

  .recipe-link:hover {
    position: relative;
    bottom: 10px;
  }

  .recipe-link:active {
    color: #000000;
  }

  .recipe-img {
    width: 100%;
    height: auto;
  }

  .recipe-name {
    font-size: 6vw;
  }

  .recipe-page-img {
    width: 25%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; /**/
    border: 4px solid black;
    border-radius: 5px; /**/
  }

  .ingredients, .instructions {
    margin-left: 25px;
    line-height: 1.5;
  }

  .ingredient-section, .instruction-section {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .citation {
    margin-bottom: 10px;
  }

  .citation a {
    color: blue;
    text-decoration: none;
  }

  .citation a:hover {
    color: lightblue;
  }

  /* Tablet Styles */
  @media only screen and (min-width: 501px) and (max-width: 960px) {
    .recipe-img {
      height: 75%;
      width: 100%;
    }

    .recipe-name {
      font-size: 2vw;
    }

    .ingrediant-subsections {
      display: flex;
      flex-wrap: wrap;
    }

    .ingrediant-subsection {
      margin-right: 10px;
    }
  }

  /* Small Desktop Styles */
  @media only screen and (min-width: 961px) {
    .recipe-link {
      width: 25%;
      height: auto;
      padding: 20px;
      font-size: 25px;
    }

    .recipe-img {
      height: 85%;
      width: 100%;
    }

    .recipe-name {
      font-size: 1.5vw;
    }

    .ingrediant-subsections {
      display: flex;
      flex-wrap: wrap;
    }

    .ingrediant-subsection {
      margin-right: 30px;
    }
  }
</style>
